.filters .filter-container {
    .filter-options-item {
        .filter-options-content {
            display: none;
            padding-bottom: 15px;

            .price-filter {
                display: flex;

                input {
                    min-width: 0;
                    padding: 0 5px;
                    font-size: 12px;
                }

                .to {
                    padding: 0 5px;
                    align-self: center;
                }

                button {
                    &:before {
                        -webkit-font-smoothing: antialiased;
                        -moz-osx-font-smoothing: grayscale;
                        font-size: 12px;
                        line-height: 1;
                        color: $color-white;
                        content: $icon-next;
                        font-family: "tickles-icons";
                        vertical-align: middle;
                        display: inline-block;
                        font-weight: normal;
                        speak: none;
                        text-align: center;
                    }

                    border: 0;
                    width: 50px;
                    min-width: 50px;
                    margin-left: 15px;
                }
            }

            .filter-item {
                &.c-checkbox {
                    .label {
                        display: flex;

                        span:not(.count) {
                            flex: 1;
                        }
                    }
                }
            }

        }

        &.active {
            .filter-options-content {
                display: block;
            }
        }
    }
}

