@include min-screen($screen__m) {
    .login-wrapper {

        display: block;

        .block-minilogin {
            right: 0;
            width: 250px;
            z-index: 101;
            overflow: hidden;
            border-radius: $dropdown-list-pointer__border-radius;
        }

        .form-login {
            padding: 15px;
        }

        .field {
            margin-bottom: 10px;
        }

        .dropdown-footer {
            padding: 15px;
            background-color: $panel__background-color;
            line-height: 1.3;
            font-size: 14px;
        }
    }

    .login-wrapper.logged-in {
        display: none;
    }

    .customer-welcome .login-wrapper {
        display: block;
    }
}

@include max-screen($screen__m) {
    .customer-welcome .login-wrapper {
        display: none;
    }
}
