//
//  General
//  _____________________________________________

.price-box {
    .price {
        display: inline-block;
    }
}

//
//  Detail page
//  _____________________________________________

.product-info-price {
    .price {
        margin-right: 30px;
    }

    .price-label {
        display: inline-block;
    }
}

//
//  Small (old-prices)
//  _____________________________________________

.price-box {
    .old-price,
    .normal-price {
        @extend .abs-price-sm;
    }

    .old-price {
        .price {
            text-decoration: line-through;
        }
    }
}

//
//  Medium (regular)
//  _____________________________________________

.price-box {
    > .price-final_price,
    .special-price,
    .normal-price,
    .minimal-price {
        .price {
            @extend .abs-price-md;
        }
    }
}

//
//  Extra Large (Detail page)
//  _____________________________________________

.product-info-price {
    .price-box {
        > .price-final_price,
        .special-price,
        .normal-price,
        .minimal-price {
            .price {
                @extend .abs-price-xl;
            }
        }
    }
}
