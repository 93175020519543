$header__background-color: $brand__primary__color !default;
$header__button__background-color: lighten($header__background-color, 5%) !default;
$header__button-size: 45px !default;

@mixin header-button-size() {
    height: $header__button-size;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-radius: 4px;
    text-decoration: none;

    &:hover {
        color: $brand__primary__color;

        &::after {
            color: $brand__primary__color;
        }
    }
}

@mixin header-button-open() {
    position: relative;
    z-index: 105;
    background-color: $color-white;
    color: $brand__primary__color;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    border-left: 1px solid $border-color__base;
    border-top: 1px solid $border-color__base;
    border-right: 1px solid $border-color__base;
}

// Add overflow hidden on page-wrapper to solve issues with login header links
.page-wrapper {
    overflow: hidden;
}

.page-header {
    @include lib-css(background-color, $color-white);
    margin-bottom: 20px;
    padding: 0;
}
.checkout-index-index {
    .header.content {
        justify-content: center;
    }
}

.header {
    &.content {
        background-color: $header__background-color;
        position: relative;
        display: flex;
        align-items: center;
        width: 100%;
        min-width: 100%;
        height: 77px;

        .logo {
            width: 116px;
        }

        .header-wrapper {
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            max-width: $layout__max-width;
            width: 100%;
            display: flex;
            align-items: center;
            justify-content: flex-end;
            position: relative;

            .left {
                min-width: calc(50% + 30px);
                display: flex;
                justify-content: flex-end;

                .logo {
                    min-width: 116px;
                    margin-right: 25px;
                }
            }

            .right {
                display: flex;
                order: 25;
            }

            .block-search {
                margin-left: 16px;
                width: 100%;
            }
        }
    }

    &-icons {
        display: flex;
        align-items: center;
        justify-content: flex-end;
        margin-left: 5px;
        margin-right: 5px;
    }

    .wishlist-wrapper {
        @include lib-list-reset-styles();
        position: relative;
        margin-left: 20px;

        .label {
            @include lib-visually-hidden();
        }

        .counter.qty {
            @extend .abs-number-badge;
        }

        a {
            @include lib-icon-font(
                $_icon-font-content: $icon-wishlist-empty,
                $_icon-font-color: $color-white,
                $_icon-font-size: 22px,
                $_icon-font-line-height: 1
            );
        }
    }

    .minicart-wrapper {
        .action.showcart {
            @include header-button-size();
            border-left: 1px solid $brand__primary__color;
            border-top: 1px solid $brand__primary__color;
            border-right: 1px solid $brand__primary__color;

            &:after {
                display: none;
            }

            &.active {
                @include header-button-open();
                padding: 0 10px;

                &:before {
                    color: $brand__primary__color;
                }

                &:after {
                    display: none;
                }
            }
        }
    }

    &.links {
        .counter.qty {
            display: none;
        }
    }
}

.logo {
    float: left;
    margin: 0 0 $indent__s $indent__xl;
    max-width: 50%;
    position: relative;
    z-index: 5;

    img {
        display: block;
    }

    .page-print & {
        float: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .sections.nav-sections {
        background: $theme-color-background-gray;
    }

    .header {
        &.panel {
            display: flex;
            justify-content: space-between;

            a {
                color: $text__color;
            }

            .topbar__list,
            > .header.links {
                @include lib-list-inline();
                font-size: 0;

                &:first-child > li {
                    margin: 0 15px 0 0;
                }

                &:last-child > li {
                    margin: 0 0 0 15px;
                }

                > li {
                    margin: 0;
                    font-size: $font-size__base;

                    &.welcome,
                    a {
                        line-height: 1.4;
                    }

                    &.welcome {
                        a {
                            @include lib-css(padding-left, $indent__xs);
                        }
                    }
                }
            }
        }

        &.content {
            .header-wrapper {
                .block-search {
                    max-width: 359px;
                }

                .header-icons {
                    .login-wrapper {
                        display: none;
                    }
                }
            }
        }

        &.links {
            @include lib-list-reset-styles();
            display: flex;
            justify-content: flex-end;
            box-sizing: border-box;
            margin-left: auto;
            margin-right: auto;
            max-width: 1168px;
            padding-left: 16px;
            padding-right: 16px;
            width: 100%;

            > li > a,
            .customer-name > span {
                color: $theme-color-almost-black;
                font-size: 14px;
                letter-spacing: 0.06em;
                font-weight: $font-weight__regular;
            }

            > li.logged-out, .customer-welcome {
                position: relative;
                margin: 0;
                padding: 0 30px;
                background-color: $theme-color-vivid-yellow;
                min-width: 70px;
                display: flex;
                align-items: center;
                justify-content: center;

                &:before,
                &:after {
                    content: '';
                    display: block;
                    position: absolute;
                    background-color: #fff;
                    transform: rotate(30deg);
                    height: 90px;
                    width: 28px;
                    left: -15px;
                    top: -22px;
                }

                &:after {
                    left: auto;
                    right: -15px;
                }

                &:hover {
                    background-color: $theme-color-yellow-hover;

                    > span {
                        background-color: $theme-color-yellow-hover;
                    }
                }
            }

            .customer-name {
                @include header-button-size();

                &[aria-expanded=true] {
                    @include header-button-open();
                    background-color: $theme-color-vivid-yellow;
                    border: 0;

                    &:hover {
                        background-color: $theme-color-yellow-hover;
                    }

                    > span {
                        color: $brand__primary__color;
                    }

                    button {
                        &::after,
                        &:hover::after,
                        &:focus::after {
                            color: $brand__primary__color;
                        }
                    }
                }

                span {
                    font-weight: 600;
                }
            }

            .customer-menu {
                .header.links {
                    margin-right: 28px;
                    box-shadow: 0px 3px 5px rgba(49, 49, 49, 0.65);
                }
            }

            .customer-welcome {
                .customer-name {
                    .action.switch {
                        &:after {
                            color: $theme-color-almost-black;
                        }
                    }
                }
            }
        }

        .wishlist-wrapper {
            a {
                @include header-button-size();

                &:hover {
                    &::before {
                        color: $header-icons-color-hover;
                    }
                }
            }
        }

        .login-wrapper {
            .action.showlogin {
                @include header-button-size();
                font-weight: 600;
                font-size: 16px;
                letter-spacing: normal;

                &.active {
                    @include header-button-open();

                    .login-wrapper {
                        box-shadow: 0 0 4px rgba(49, 49, 49, 0.65);
                    }
                }

                &:hover {
                    color: $theme-color-almost-black;

                    &::after {
                        color: $theme-color-almost-black;
                    }
                }
            }
        }
    }

    .page-header {
        border: 0;
        margin-bottom: 0;

        .panel.wrapper {
            border-bottom: 1px solid $secondary__color;
        }

        .header.panel {
            padding-bottom: $indent__s;
            padding-top: $indent__s;
        }

        .switcher {
            display: inline-block;
        }
    }

    .logo {
        margin: 0;
    }

}

//
//  Large Desktops
//  _____________________________________________

@include min-screen($screen__l) {
    .header {
        &.links {
            flex: 1;
        }
    }
}

//
//  Screens between 768 and 1024
//  _____________________________________________

@include screen($screen__m, $screen__l) {
    .block-search {
        flex: 1;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .page-header {
        padding: 0;
    }

    .header {
        .wishlist-wrapper {
            display: none;
        }

        &.content {
            display: flex;
            align-items: center;
            justify-content: space-between;
            flex-wrap: wrap;
            position: relative;
            padding: $indent__s;
            height: auto;
            width: auto;
            min-width: 0;

            &::before,
            &::after {
                display: none;
            }

            .logo {
                margin: 0;
            }

            .block-search {
                width: 100%;
            }

            .block-search .label:before {
                margin-right: 0;
            }

            .form.minisearch.active {
                .control {
                    background-color: $color-white;
                    position: absolute;
                    left: 0;
                    width: 100%;
                    bottom: -63px;
                    z-index: 400;
                }

                .top-search__container {
                    padding: 0 15px;
                    border-bottom: 1px solid $border-color__base;
                }
            }

            .header-wrapper {
                flex-wrap: wrap;
                justify-content: space-between;

                .left {
                    min-width: calc(60% + 30px);
                    justify-content: flex-end;
                }

                .block-search {
                    margin-left: 0;
                }

                .right {
                    order: 2;
                    display: flex;
                    justify-content: flex-end;
                    width: 20%;
                }

                .login-wrapper {
                    &::marker {
                        content: none;
                    }

                    a {
                        @include lib-icon-font(
                            $_icon-font-content: $icon-account,
                            $_icon-font-color: $color-white,
                            $_icon-font-size: 22px,
                            $_icon-font-line-height: 1
                        );

                        span {
                            display: none;
                        }
                    }
                }

            }
        }

        &.links {
            display: none;
        }

        .minicart-wrapper{
            .action.showcart {
                margin-left: 10px;
            }
        }

        .nav-toggle,
        &-icons {
            flex: 1;
        }

        .nav-toggle {
            order: 1;
        }

        .logo {
            order: 2;
        }

        &-icons {
            order: 3;
        }

        .block-search {
            order: 4;
        }
    }
}
