@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/frutiger-neue/regular/FrutigerNeueLTW1G-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/frutiger-neue/medium/FrutigerNeueLTW1G-Medium',
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-name__base,
    $font-path: '../fonts/frutiger-neue/bold/FrutigerNeueLTW1G-Bold',
    $font-weight: bold,
    $font-style: normal
);

// WorkSans fonts
@include lib-font-face(
    $family-name: $font-family-work-sans,
    $font-path: '../fonts/work-sans/regular/WorkSans-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-work-sans,
    $font-path: '../fonts/work-sans/medium/WorkSans-Medium',
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-work-sans,
    $font-path: '../fonts/work-sans/bold/WorkSans-Bold',
    $font-weight: bold,
    $font-style: normal
);

//LarkenDemo fonts
@include lib-font-face(
    $family-name: $font-family-larken-demo,
    $font-path: '../fonts/larken-demo/regular/LarkenDEMO-Regular',
    $font-weight: 400,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-larken-demo,
    $font-path: '../fonts/larken-demo/medium/LarkenDEMO-Medium',
    $font-weight: 500,
    $font-style: normal
);

@include lib-font-face(
    $family-name: $font-family-larken-demo,
    $font-path: '../fonts/larken-demo/bold/LarkenDEMO-Bold',
    $font-weight: bold,
    $font-style: normal
);

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    h1 {
        @include lib-css(font-size, $h1__font-size-desktop);
        @include lib-css(margin-bottom, $h1__margin-bottom__desktop);
    }
}

.items {
    @include lib-list-reset-styles();
}

.std {
    @include lib-typography-cms-lists();
}
