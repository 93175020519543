.category-grid {
    @include make-row();
    margin-bottom: -30px;

    &__item {
        @include make-col-ready();
        @include make-col(12);
        margin-bottom: 30px;
    }

    &__anchor {
        @include lib-link(
            $_link-color                   : $text__color,
            $_link-color-visited           : $text__color,
            $_link-color-hover             : $text__color,
            $_link-color-active            : $text__color
        );
        font-weight: $font-weight__medium;
    }
}

//
//  Mobile
//  _____________________________________________

@include min-screen($screen__s) {
    .category-grid {
        &__item {
            @include make-col(8);
        }
    }
}

//
//  Tablet
//  _____________________________________________

@include min-screen($screen__m) {
    .category-grid {
        &__item {
            @include make-col(6);
        }
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__l) {
    .category-grid {
        &__item {
            @include make-col(4);
        }
    }
}
