.home-template-wrapper {
    display: flex;
    padding-bottom: 180px;

    &__intro {
        background: $theme-color-background-gray;
        padding: 48px 40px 0 40px;
    }

    &__inner {
        display: flex;

        h2 {
            position: relative;
            font-weight: normal;
            margin-bottom: 20px;
            font-family: $font-family-larken-demo;
            font-size: 32px;
            color: $theme-color-almost-black;
        }
    }

    &__left,
    &__right {
        width: 50%;
    }

    &__right {
        display: flex;
    }

    &__left {
        position: relative;
        padding-right: 40px;

        p {
            font-size: 16px;
            line-height: 24px;
        }

        ul {
            display: flex;
            flex-direction: column;
            list-style: none;
            padding: 0;
            margin: 36px 0 0 0;

            li {
                padding: 10px 0;
                margin: 0;
                border-bottom: 1px solid $border-color__base;
            }

            a {
                @include lib-link-all(
                    $_link-color: $brand__primary__color
                );
                @include lib-icon-font(
                    $_icon-font-content: $icon-arrow-right,
                    $_icon-font-position: after
                );
                font-weight: $font-weight__semibold;
                font-size: 16px;

                &::after {
                    position: absolute;
                    right: 40px;
                }

                &:hover {
                    text-decoration: none;
                }
            }
        }
    }

    .sidebar-main {
        margin-bottom: 40px;
    }

    .sidebar-container {
        padding: 48px 53px 0 0;
    }

    .sidebar-categories {
        &__list {
            li {
                position: relative;
                margin-bottom: 11px;
            }

            .count {
                @extend .count;
            }
        }

        &__anchor {
            font-size: 16px;
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .home-template-wrapper {
        flex-direction: column;
        padding-bottom: 40px;

        &__inner {
            flex-direction: column;
        }

        &__left,
        &__right {
            width: 100%;
        }

        .sidebar-container {
            padding: 0;
        }
    }
}

@include screen($screen__m, $screen__l) {
    .home-template-wrapper {
        overflow: hidden;

        &__right {
            img {
                position: absolute;
                right: 0;
                bottom: 0;
                width: 50%;
            }
        }
    }
}
