.customer-account-index, .customer-address-index {
  /** FC-41: Simply hiding these elements is enough, direct navigating to their URL's will result in a 404 anyway. */
  .block-dashboard-info {
    .box-actions {
      display: none;
    }
  }

  .box-shipping-address, .box-address-shipping {
    display: none;
  }
}
