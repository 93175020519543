$footer__background-color : $theme-color-slate-green !default;

.page-footer {
    @include lib-css(background-color, $footer__background-color);
    position: relative;
    overflow: hidden;

    .blue-block {
        display: block;
        position: absolute;
        background-color: $theme-color-morning-dew-blue;
        transform: rotate(30deg);
        height: 500px;
        width: 215px;
        right: -25px;
        bottom: -230px;
    }
}

.before-footer-wrapper {
    text-align: center;
    margin-bottom: 45px;
}

.footer {
    &.content {
        .links {
            > li {
                margin: 0 0 8px;
            }
        }

        .switcher-store {
            margin: 0 0 30px;
        }
    }
}

.footer-menu {
    &,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }
}

.footer-bottom {
    margin-bottom: 40px;
    text-align: center;
}

.footer-copyright {
    font-size: 14px;
    color: $theme-color-dark-gray6;
    width: 24%;
    display: flex;
    margin-top: 5px;
    align-items: center;
}

.footer-payment-providers {
    margin-bottom: 10px;
}

.after-footer-columns {
    .block.newsletter {
        margin-bottom: 20px;
    }

    p {
        margin-bottom: 20px;
    }
}

.top-footer {
    margin-bottom: 40px;
    padding: 25px 0;
    background-color: $brand__primary__color;
    color: $color-white;

    &__left {
        margin: 5px 15px 5px 0;
        font-size: 22px;
    }

    &__right {
        ul {
            @include lib-list-reset-styles();
            display: flex;
        }

        li {
            @include lib-icon-font(
                $_icon-font-content: false,
                $_icon-font-margin: 0 10px 0 0,
                $_icon-font-size: 22px
            );
            margin: 0;
            font-weight: $font-weight__medium;

            &:nth-child(1) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-delivery
                );

                &::before {
                    font-size: 20px;
                }
            }

            &:nth-child(2) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-undo
                );
            }

            &:nth-child(3) {
                @include lib-icon-font-symbol(
                    $_icon-font-content: $icon-moon
                );

                &::before {
                    font-size: 19px;
                }
            }

            &:not(:first-child) {
                margin-left: 25px;
            }
        }
    }
}

@include min-screen($screen__m) {
    .footer-menu {
        &__sub-menu-link {
            @include lib-link-all(
                $_link-color: $color-white
            );
            font-size: 16px;
        }

        &__sub-menu-topall {
            display: none;
        }

        &__top-link {
            @include lib-heading(h4);
            @include lib-link-all(
                $_link-color: $color-white
            );
            margin-bottom: 15px;
            display: inline-block;
        }

        &__sub-menu-item {
            margin-bottom: 15px;
            line-height: 1.2;
            font-size: 14px;
        }
    }

    .footer-columns {
        display: flex;
        flex-wrap: wrap;
        margin-bottom: 60px;
        padding-top: 90px;

        .after-footer-columns {
            @include make-col(9);
        }
    }

    .footer-menu-container,
    .after-footer-columns {
        @include make-col-ready();
    }

    .before-footer-columns {
        margin-top: 5px;
        width: 25%;

        img {
            height: 40px;
        }
    }

    .footer-menu-container {
        width: 50%;
        padding-left: 0;
    }

    .footer-menu {
        display: flex;

        .footer-menu-wrapper {
            display: flex;
            width: 100%;
        }

        &__item {
            flex: 1;
            max-width: 340px;
        }
    }

    .footer-bottom {
        margin-bottom: 60px;
    }

    .footer {
        &.content {
            .links {
                display: inline-block;
                margin-bottom: 20px;
                padding: 0 50px 0 0;
                vertical-align: top;
            }

            .switcher.store {
                display: inline-block;
                padding-right: 50px;
                vertical-align: top;
            }
        }

        .copyright {
            @extend .abs-add-clearfix-desktop;
        }
    }

    .top-footer {
        margin-bottom: 60px;

        &__inner {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;
        }
    }
}

@media (min-width: $screen__l) and (max-width: 1200px) {
    .page-footer {
        .blue-block {
            width: 160px;
        }
    }
}

@media (min-width: $screen__m) and (max-width: $screen__l) {
    .page-footer {
        .blue-block {
            width: 110px;
        }
    }

    .before-footer-columns {
        width: 29%;
    }

    .footer-copyright {
        margin-left: -40px;
    }
}

//
//  Mobile
//  _____________________________________________
@include max-screen($screen__m) {
    .page-footer {
        min-height: 250px;

        .blue-block {
            width: 110px;
            bottom: -208px;
        }
    }

    .footer.content .footer-menu {
        margin-right: -$layout-indent__width;
        margin-left: -$layout-indent__width;
        display: flex;
        justify-content: center;

        .footer-menu-wrapper {
            display: flex;
            justify-content: space-between;
            max-width: 450px;
            width: 80%;
        }

        &__item {
            @include lib-icon-font(
                $_icon-font-content: $icon-next,
                $_icon-font-display: block,
                $_icon-font-color: $theme-color-dark-gray6
            );
            position: relative;
            margin: 0;
            padding: 15px 35px 15px $layout-indent__width;
            border-bottom: 1px solid $border-color__base;
            font-size: 16px;
            font-weight: $font-weight__medium;

            &::before {
                position: absolute;
                top: 50%;
                right: $layout-indent__width;
                transform: translateY(-50%);
            }

            &--active {
                &::before {
                    transform: rotate(90deg) translateX(-50%);
                }
            }

            &.parent {
                visibility: hidden;
            }
        }

        &__top-link {
            @include lib-link-all(
                $_link-color: $color-white
            );
        }

        &__sub-menu-link {
            @include lib-link-all(
                $_link-color: $color-white
            );
            font-size: 16px;
        }

        &__sub-menu-item {
            visibility: visible;
        }
    }

    .footer-columns {
        margin: 0;

        .block.newsletter {
            display: none;
        }

        .social-media {
            margin-top: 20px;
        }
    }

    .top-footer {
        display: none;
    }

    .before-footer-columns {
        display: flex;
        flex-direction: column;
        align-items: baseline;
        padding-top: 40px;
        margin: 0 auto;
        order: 2;

        img {
            height: 40px;
            margin-bottom: 20px;
        }
    }

    .footer-columns {
        display: flex;
        flex-direction: column;
    }

    .before-footer-wrapper {
        margin: 0;
        line-height: 0;

        a {
            line-height: 0;
        }
    }

    .footer-menu-container {
        order: 1;
    }

    .footer-copyright {
        width: 100%;
        order: 3;
        justify-content: center;
    }
}
//
//  Small Mobile
//  _____________________________________________
@include max-screen($screen__xs) {
    .page-footer {
        .footer.content .footer-menu {
            .footer-menu-wrapper {
                width: 100%;
            }
        }

        .blue-block {
            width: 80px;
            bottom: -208px;
        }
    }
}
