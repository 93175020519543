//
//  Variables
//  _____________________________________________

$block-auth__dropdown__padding          : $indent__m !default;
$block-auth__dropdown__background-color : $color-white !default;
$block-auth__or-label__size             : 36px !default;
$block-auth__width                      : 0 !default;
$block-auth__border                     : 1px solid $color-gray-light3 !default;

.authentication-dropdown {
  box-sizing: border-box;

  .modal-inner-wrap {
    padding: $block-auth__dropdown__padding;
  }
}
.authentication-wrapper {
  float: right;
  margin-top: -1.5 * $indent__xl;
  max-width: 50%;
  position: relative;
  z-index: 1;

  ._has-auth-shown & {
    z-index: $modal__z-index;
  }
}

.popup-authentication {
    .modal-header {
        padding: 0;
        border: 0;
    }

    .block-customer-login {
        .subtitle {
            font-size: 14px;
            margin-bottom: 20px;

            p {
                margin-bottom: 0;
            }
        }
    }

    button.action-close {
        position: absolute;
        right: 20px;
        top: 15px;
    }
}

.action-auth-toggle {
  @extend .abs-action-button-as-link;
}

.block-authentication {
  .block-title {
    @include lib-font-size($h3__font-size);
    border-bottom: 0;
    margin-bottom: $indent__m;

    strong {
      font-weight: $font-weight__light;
    }
  }

  .field {
    .label {
      font-weight: $font-weight__regular;
    }
  }

  .actions-toolbar {
    margin-bottom: $indent__xs;

    > .secondary {
      padding-top: $indent__m;
      text-align: left;
    }
  }

  .action.action-register,
  .action.action-login {
    @extend .abs-button-l;
  }

  .block[class] {
    margin: 0;

    ul {
      list-style: none;
      padding-left: $indent__s;
    }

    .field {
      .control,
      .label {
        float: none;
        width: auto;
      }
    }

    & + .block {
      border-top: 1px solid $color-gray-light5;
      margin-top: $indent__xl;
      padding-top: $indent__xl;
      position: relative;

      &::before {
        @include lib-css(height, $block-auth__or-label__size);
        @include lib-css(line-height, $block-auth__or-label__size - 2px);
        @include lib-css(margin, -($block-auth__or-label__size/2 + 1px) 0 0 -($block-auth__or-label__size / 2));
        @include lib-css(min-width, $block-auth__or-label__size);
        background: $color-white;
        border: 1px solid $color-gray-light5;
        border-radius: 50%;
        box-sizing: border-box;
        color: $color-gray-light5;
        content: attr(data-label);
        display: inline-block;
        left: 50%;
        letter-spacing: normal;
        padding: 0 0.2rem;
        position: absolute;
        text-align: center;
        text-transform: uppercase;
        top: 0;
      }
    }
  }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
  .authentication-dropdown {
    @include lib-css(background-color, $block-auth__dropdown__background-color);
    @include lib-css(border, $block-auth__border);
    position: absolute;
    text-align: left;
    top: 100%;
    transform: scale(1, 0);
    transform-origin: 0 0;
    transition: transform linear 0.1s, visibility 0s linear 0.1s;
    visibility: hidden;
    width: 100%;

    &._show {
      @include lib-css(z-index, $dropdown-list__z-index);
      transform: scale(1, 1);
      transition: transform linear 0.1s, visibility 0s linear 0s;
      visibility: visible;
    }
  }

  .authentication-wrapper {
    @include lib-column-width($checkout-sidebar__columns);
    text-align: right;
  }

  .block-authentication {
    .block-title {
      @include lib-font-size($h2__font-size);
      border-bottom: 0;
      margin-bottom: $indent__m;
    }

    .actions-toolbar {
      > .primary {
        display: inline;
        float: right;
        margin-right: 0;
        .action {
          margin-right: 0;
        }
      }

      > .secondary {
        float: left;
        margin-right: 2rem;
        padding-top: 1rem;
      }
    }
  }

  .popup-authentication {
    .modal-inner-wrap {
      min-width: $screen__m;
      width: 60%;
    }

    .block-authentication {
      @include lib-vendor-prefix-display(flex);
      @include lib-vendor-prefix-flex-direction(row);
    }

    .block[class],
    .form-login,
    .fieldset,
    .block-content {
      @include lib-vendor-prefix-display(flex);
      @include lib-vendor-prefix-flex-direction(column);
      @include lib-vendor-prefix-flex-grow(1);
    }

    .block[class] {
      box-sizing: border-box;
      float: left;
      padding: $indent__s $indent__l 0 0;
      width: 50%;

      & + .block {
        margin: 0;
        padding: $indent__s 0 0 $indent__xl;

        &::before {
          left: 0;
          top: 50%;
        }
      }
    }

    .actions-toolbar {
      margin-bottom: 0;
      margin-top: auto;
    }
  }
}
