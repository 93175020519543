.sidebar-categories {
    &__title {
        font-weight: normal;
        margin-bottom: 20px;
        font-family: $font-family-larken-demo;
        font-size: 32px;
        color: $theme-color-almost-black;
    }

    &__list {
        @include lib-list-reset-styles();
    }

    &__anchor {
        @include lib-link-all(
            $_link-color: $text__color
        );
    }

    &__show-more {
        @include lib-button-as-link();
        @include lib-link-all(
            $_link-color: $brand__primary__color
        );
    }

    &__list:not(.active) &__list-item--outside-limit {
        display: none;
    }
}

.pop-link-wrapper {
    display: flex;

    .sidebar-block-wrapper {
        width: 50%;

        &:nth-child(1) {
            margin-right: 40px;
        }
    }
}

.sidebar-block-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    background: $theme-color-background-gray;
    padding: 40px;
    height: 168px;

    a {
        @include lib-link-all(
            $_link-color: $brand__primary__color
        );
        @include lib-icon-font(
            $_icon-font-content: $icon-arrow-right,
            $_icon-font-position: after
        );
        font-weight: $font-weight__semibold;
        font-size: 16px;

        &::after {
            transition: 200ms ease;
            margin-top: -2px;
            margin-left: 10px;
        }

        &:hover {
            text-decoration: none;

            &::after {
                margin-left: 15px;
            }
        }
    }

    h3 {
        text-align: center;
        margin: 25px 0;
        line-height: 1.4;
        color: $theme-color-almost-black;
        font-weight: 600;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .sidebar-block-wrapper {
        padding: 20px;
        margin-bottom: 20px;
    }
}

@include max-screen($screen__l) {
    .catalog-category-view {
        .sidebar-container {
            order: 1;
        }
    }

    .sidebar-categories {
        display: none;
    }
}

