.navigation-menu {
    &__list,
    &__sub-menu-row,
    &__sub-menu-list {
        @include lib-list-reset-styles();
    }

    &__sub-menu-top-link {
        @include lib-link(
            $_link-color                   : $text__color,
            $_link-color-visited           : $text__color,
            $_link-color-hover             : $text__color,
            $_link-color-active            : $text__color
        );
        font-weight: $font-weight__medium;
    }
}

@include min-screen($screen__m) {
    .navigation-menu {
        &-container {
            position: relative;
        }

        &__list {
            display: flex;
            overflow-x: auto;
        }

        &__item {
            margin: 0 40px 0 0;

            a {
                @include lib-link-all(
                    $_link-color: $theme-color-almost-black
                );
            }
        }

        &__top-link {
            display: inline-block;
            padding: 14px 0;
            font-weight: normal;
            font-size: 15px;
        }

        &__sub-menu-container {
            position: absolute;
            z-index: 2;
            top: 100%;
            left: 0;
            right: 0;
            width: 100%;
            padding: 30px 0 10px;
            background-color: $color-white;
            border-top: 1px solid $border-color__base;
            border-bottom: 1px solid $border-color__base;
            box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.1);

            a {
                @include lib-link-all(
                    $_link-color: $theme-text-color
                );
            }
        }

        &__sub-menu-row {
            @include make-row();
        }

        &__sub-menu-column {
            @include make-col-ready();
            @include make-col(8);
            margin-bottom: 20px;
        }

        &__sub-menu-list {
            margin-top: 5px;
        }

        &__top-linkall,
        &__sub-menu-top-linkall {
            display: none;
        }

        &__sub-menu-item {
            display: block;
            line-height: 1.2;
            margin: 0;

            &:not(:last-child) {
                margin-bottom: 10px;
            }
        }
    }
}

@include min-screen($screen__l) {
    .navigation-menu {
        &__sub-menu-column {
            @include make-col(6);
        }
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .nav-sections {
        a {
            @include lib-link(
                $_link-color                   : $text__color,
                $_link-color-visited           : $text__color,
                $_link-color-hover             : $text__color,
                $_link-color-active            : $text__color
            );
        }
    }

    .navigation-menu,
    .nav-sections .footer-menu {
        a {
            display: block;
            padding: $navigation-level0-item__padding;
            border-bottom: 1px solid $color-gray82;

            &:hover {
                text-decoration: none;
            }
        }

        &__item.parent {
            @include lib-icon-font(
                $_icon-font-content  : $icon-down,
                $_icon-font-size     : 42px,
                $_icon-font-position : after,
                $_icon-font-display  : block
            );
            position: relative;

            &::after {
                @include lib-font-size(14);
                position: absolute;
                right: 17px;
                top: 12px;
                pointer-events: none;
            }
        }

        &__item {
            margin: 0;

            &--active.parent {
                @include lib-icon-font-symbol(
                    $_icon-font-content  : $icon-up,
                    $_icon-font-position : after
                );
            }
        }

        &__top-linkall {
            margin-bottom: 10px;
        }
    }
}

//
//  Debugging
//  _____________________________________________

//.navigation-menu__item:first-child > div {
//    display: block !important;
//}
