.pages {
    @include lib-pager();
    
    .action {
        &.previous {
            margin-right: 10px;
        }
        
        &.next {
            margin-left: 10px;
        }
    }
    
    .action {
        height: 35px;
        line-height: 30px;
    }
    
    .page,
    .jump {
        justify-content: center;
        text-align: center;
        width: 35px;
        height: 35px;
        font-size: 16px;
        line-height: 38px;
    }
}

@include max-screen($screen__s) {
    .pages {
        .items {
            justify-content: center;
        }
        
        .pages-item-previous,
        .pages-item-next {
            width: 100%;
            justify-content: center;
        }
        
        .pages-item-previous {
            margin-bottom: 10px;
        }
        
        .pages-item-next {
            margin-top: 10px;
        }
    }
}
