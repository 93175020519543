//
//  Product Lists
//  _____________________________________________

.product-overview.grid {
    .product-item {
        margin-bottom: 50px;
    }
}

.products {
    margin-top: $indent__l;
    margin-bottom: $indent__l;

    &.products-related,
    &.products-upsell,
    &.products-crosssell {
        .product-items {
            display: flex;
            flex-wrap: wrap;
            width: 100%;

            &.swiper-wrapper {
                flex-wrap: nowrap;
            }
        }
    }
}

.product {
    &__top {
        min-height: 72px;
    }

    &-items {
        @extend .abs-reset-list;
    }

    &-item {
        @extend .abs-add-box-sizing;
        vertical-align: top;

        .products-grid & {
            display: inline-block;
        }

        .products-grid &-name {
            font-size: 14px;
        }

        .grade-wrapper {
            font-size: 13px;
            margin-bottom: 30px;
        }

        .price-cart-wrapper {
            display: flex;
            align-items: center;
            justify-content: space-between;
        }

        &-name {
            @extend .abs-product-link;
            display: block;
            word-wrap: break-word;
            hyphens: auto;
            margin-bottom: 5px;
        }

        &-info {
            max-width: 100%;
        }

        &-actions {
            .actions-secondary {
                & > .action {
                    @extend .abs-actions-addto;

                    &:before {
                        margin: 0;
                    }

                    span {
                        @extend .abs-visually-hidden;
                    }
                }
            }
        }

        .product-reviews-summary {
            .rating-summary {
                margin: 0 4px 0 0;
            }

            .reviews-actions {
                font-size: $font-size__s;
                margin-top: 5px;
                text-transform: lowercase;
            }
        }

        .price-final_price.price-box {
            display: flex;
            flex-wrap: wrap;
            align-items: center;

            .price {
                white-space: nowrap;
            }

            .price-label {
                display: none; // Hide the price label
            }
        }

        .special-price,
        .minimal-price {
            margin: 0;

            .price-wrapper {
                display: inline-block;
            }

            .price-including-tax + .price-excluding-tax {
                display: block;
            }
        }

        .special-price {
            display: block;
        }

        .minimal-price {
            .price-container {
                display: block;
            }
        }

        .minimal-price-link {
            margin-top: 5px;
        }

        .minimal-price-link,
        .price-excluding-tax,
        .price-including-tax {
            display: block;
            white-space: nowrap;
        }

        .price-from,
        .price-to {
            margin: 0;
        }

        .tocompare {
            @include lib-icon-font-symbol($icon-compare-full);
        }

        .tocart {
            white-space: nowrap;
            background-color: $theme-color-vivid-yellow;
            background: $theme-color-vivid-yellow;
            color: $theme-color-almost-black;
            border: none;
            position: relative;
            overflow: hidden;

            &:hover {
                border: none;
                background-color: $theme-color-yellow-hover;
                background: $theme-color-yellow-hover;
                color: $theme-color-almost-black;

                &:before {
                    right: -32px;
                }
            }

            &:focus {
                border: none;
                background-color: $theme-color-vivid-yellow;
                background: $theme-color-vivid-yellow;
                color: $theme-color-almost-black;
            }

            &:before {
                background: linear-gradient(90deg, $theme-color-button-effect 32px, $theme-color-vivid-yellow 0);
                bottom: -1px;
                content: "";
                position: absolute;
                right: -60px;
                top: -1px;
                transform: skew(-30deg);
                transform-origin: top;
                transition: right .3s;
                width: 32px;
                will-change: transform;
            }
        }
    }
}

.products-list {
    .product-item .price-cart-wrapper {
        flex-direction: column;
        align-items: flex-start;
    }
}

.price-container {
    .price-including-tax + .price-excluding-tax,
    .weee {
        margin-top: $indent__xs;
    }

    .weee {
        &:before {
            content: '(' attr(data-label) ': ';
        }

        &:after {
            content: ')';
        }

        + .price-excluding-tax {
            &:before {
                content: attr(data-label) ': ';
            }
        }
    }
}

.products-list {
    .photo-wrapper {
        position: relative;
        margin-right: 20px;
    }

    .product {
        &-item {
            width: 100%;

            &.item {
                padding-left: 0;
            }

            &-name {
                margin-top: 0;
            }

            &-info {
                display: flex;
                padding: 20px 0;
                border-bottom: 1px solid $border-color__base;
            }
        }

        .price-box {
            margin-bottom: 5px;
        }
    }

    .product-image-wrapper {
        @extend .abs-reset-image-wrapper;
    }
}

//
//  Mobile
//  _____________________________________________

@include max-screen($screen__m) {
    .products-grid {
        .products.product-items {
            .swatch-attribute {
                display: none;
            }
        }
    }

    .products-list .product-item-info {
        display: block;
    }
}

@include max-screen($screen__s) {
    .products-list .product {
        &-item {
            table-layout: fixed;

            &-photo {
                padding: 0 $indent__s $indent__s 0;
                width: 30%;
            }
        }
    }

    .product-reviews-summary,
    .product-item-details [class^=swatch] {
        display: none;
    }
}

//
//  Desktop
//  _____________________________________________

@include min-screen($screen__m) {
    .product {
        &-item {
            .products-list &-name {
                font-size: 14px;
            }

            &-actions {
                display: flex;
                flex-wrap: wrap;
                align-items: center;

                .actions-primary + .actions-secondary {
                    margin: 10px 0;
                    padding-left: 10px;
                    white-space: nowrap;

                    & > * {
                        white-space: normal;
                    }

                    & > .action:not(:last-child) {
                        margin-right: 10px;
                    }
                }
            }
        }
    }
}

@media (min-width: 1024px) and (max-width: 1024px) {
    .catalog-category-view {
        &.page-layout-2columns-left {
            .columns {
                .column.main {
                    flex: 0 0 75%;
                    max-width: 75%;
                }
            }
        }
    }
}

